@font-face {
    font-family: 'Impostograph';
    src: local('Impostograph'), url(../public/Impostograph-Regular.ttf) format('truetype');
}

body {
    color: white;
}

.rowGroupHeader {
    font-family: 'Impostograph';
    font-size: 40px;
    margin-top: 0.25cm;
}

.impostorFont{
    font-family: 'Impostograph';
    font-size: 40px;
}

.playButton{
    margin-top:7px;
    margin-left:5px;
    background-color: black;
    color: white;
    border-color: white;
    border-radius: 10px;
    border-width: thick;
    border-style: solid;
}

